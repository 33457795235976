/**
 * Javascript for Essence Ads
 *
 **/

/**
 * Prepares and sends data from ad tags to Freestar for ad rendering.
 */
// Object from Ajax
const viewPortHeight = Math.max(
  document.documentElement.clientHeight,
  window.innerHeight || 0
);

const isMobileDevice = window.matchMedia(
  "only screen and (max-width: 760px)"
).matches;

let viewPortMultiplier = isMobileDevice ? 0.9 : 0.5;
// Google Optimize Test override
if (typeof window.viewPortMultiplier !== "undefined") {
  viewPortMultiplier = window.viewPortMultiplier;
}

const rootMargin = Math.floor(viewPortMultiplier * viewPortHeight);

// Observer to detect ads within 3 viewport view
const ads_observer = new IntersectionObserver(
  (entries, observer) => {
    let els = [];

    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        const el = $(entry.target);
        el.addClass("freestar-pushed");
        els.push(el);
        observer.unobserve(entry.target);
      }
    });

    // Testing outstream ads (EDP-2363)
    // Via query string...
    let url_params = new URLSearchParams(window.location.search);
    let outstream_slot = url_params.get("outstream") ?? false;
    let outstream_width = url_params.get("width") ?? "100%";
    let outstream_height = url_params.get("height") ?? false;
    // Via cookie...
    if (!outstream_slot) {
      let outstream_cookie = document.cookie
        .split(";")
        .find((c) => c.trim().startsWith("ess_outstream="));
      if (outstream_cookie) {
        let cookie_value = outstream_cookie.split("=")[1];
        let cookie_object = JSON.parse(cookie_value);
        outstream_slot = cookie_object.slot;
        outstream_width = cookie_object.width;
        outstream_height = cookie_object.height;
      }
    }
    if (outstream_slot) {
      runOutstreamTest(els, outstream_slot, outstream_width, outstream_height);
    } else {
      pushToFreestar(els);
    }
    // END TEST
  },
  {
    rootMargin: `0px 0px ${rootMargin}px 0px`,
    threshold: 0,
  }
);

let count = 0;
let positions = ".ad-position";

// Testing outstream ads (EDP-2363)
const runOutstreamTest = (
  els,
  outstream_slot,
  outstream_width,
  outstream_height
) => {
  // Store the ad unit specs during infinate scroll
  let adUnitSpecs = {
    slot: outstream_slot,
    width: outstream_width,
    height: outstream_height,
  };
  document.cookie = `ess_outstream=${JSON.stringify(
    adUnitSpecs
  )};path=/;max-age=300`;

  els.forEach(function (el) {
    let slot_id = el.attr("id");
    let placement = el.attr("data-placement-name");

    if (placement.endsWith(outstream_slot)) {
      const slot = document.getElementById(slot_id);
      if (
        slot &&
        !document.getElementById("jwp-outstream-unit_" + outstream_slot)
      ) {
        // Create the ad container
        slot.innerHTML = `<center><div id='jwp-outstream-unit_${outstream_slot}'></div></center>`;
        const outstream_unit = document.getElementById(
          "jwp-outstream-unit_" + outstream_slot
        );
        // Create the style element
        var style = document.createElement("style");
        style.type = "text/css";
        style.textContent = `
          .ad-position.sticky {
              z-index: 100;
              position: sticky !important;
              transition: opacity 0.5s ease-in-out;            
          }
          .ad-position.sticky {
                top: 70px;
          }                    
        `;
        // Create the script element
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.textContent = `
          console.log('Loading outstream player in placement: ${placement}');
          function waitForJwplayer(callback) {
            if (typeof jwplayer !== 'undefined') {
              callback();
            } else {
              setTimeout(function() {
                waitForJwplayer(callback);
              }, 500);
            }
          }
          waitForJwplayer(function() {
            let outstream_container = jQuery(document.querySelector(\`[data-placement-name="essence_${outstream_slot}"]\`));
            
            // Set leaderboard height
            if ( 'leaderboard' === '${outstream_slot}' ) {
              outstream_container.css('height', ${outstream_height} + 'px');
            } else {
              let leaderboard = jQuery(document.querySelector(\`[data-placement-name="essence_leaderboard"]\`));
              leaderboard.removeClass('sticky');
            }
            
            // Make sticky for 2 seconds after loading.
            if ( 'leaderboard' === '${outstream_slot}' ) {
              outstream_container.addClass('sticky');
              jQuery(window).on(
                "scroll",
                essThrottle(function () {
                  if (!jQuery(window).scrollTop()) {
                    return; // return if page is not scrolled
                  }
                  setTimeout(function () {
                    outstream_container.addClass('fade');
                    setTimeout(() => {
                      outstream_container.removeClass('sticky fade');
                    }, 500);
                  }, 2000);
                })
              );                
            }
            
            // Make sticky for 2 seconds after intersecting top nav.
            if ( 'incontent' === '${outstream_slot}' || 'right_rail' === '${outstream_slot}' || 'infinite_scroll' === '${outstream_slot}' ) {
              outstream_container.addClass('sticky');
              outstream_container.observer = new IntersectionObserver(
                (entries, observer) => {
                  entries.forEach((entry) => {
                    if (entry.intersectionRatio && entry.intersectionRatio < 1) {
                      if (entry.boundingClientRect.top <= 71) {
                        // If intersecting at the top of the browser, remove sticky after 2 seconds.
                        setTimeout(() => entry.target.classList.remove("sticky"), 2000);
                      } else {
                        // If intersecting at the bottom of the browser, add sticky back.
                        entry.target.classList.add("sticky");
                      }
                    }
                  });
                },
                {
                  rootMargin: \`-71px 0px 0px 0px\`, 
                  threshold: [1],
                }
              );
              outstream_container.observer.observe(outstream_container.get(0));
            }
            
            jwplayer('jwp-outstream-unit_${outstream_slot}').setup({
              'width': ${outstream_width},
              'height': ${outstream_height},
              'floating': {
                'mode': 'never'
              },
              'advertising': {
                'client': 'googima',
                'outstream': true,
                'tag': 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21698916284/ess/videooutstream&description_url=http%3A%2F%2Fessence.com&tfcd=0&npa=0&sz=1000x1&max_ad_duration=15000&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=',
                'endstate': 'close'
              }
            });
          });
        `;
        slot.insertBefore(script, outstream_unit.nextSibling);
        slot.insertBefore(style, outstream_unit.nextSibling);
      }
    }
  });
};
// END TEST

const pushToFreestar = (els) => {
  let els_to_push = [];

  els.forEach(function (el) {
    // Tell Freestar which element to use.
    let slot_id = el.attr("id");
    if (
      !isMobileDevice &&
      typeof slot_id === "string" &&
      slot_id.includes("_mobile_")
    ) {
      return;
    }

    // Tell Freestar which type of ad to send.
    let placement = el.attr("data-placement-name");

    let article_key_values = {};
    article_key_values.pos = slot_id;

    if (dfp_ad_object[0].test !== null) {
      article_key_values.test = dfp_ad_object[0].test;
    }

    // Only send the tile value if the current ad is not outofpage 1x1.
    if (!el.hasClass("dfp-ads-outofpage")) {
      count++;
      article_key_values.tile = count;
    }

    if (slot_id.includes("leaderboard")) {
      article_key_values.inDapIF = true;
    }

    // Bring the page targeting & article_key_values values into article_targeting together
    // so that overwriting values within objects does not occur.
    let article_targeting = Object.assign(
      article_key_values,
      page_targeting(el)
    );

    els_to_push.push({
      placementName: placement,
      slotId: slot_id,
      targeting: article_targeting,
    });
  });

  freestar.queue.push(function () {
    freestar.newAdSlots(els_to_push);
  });
};

const page_targeting = (el) => {
  const krux_data =
    typeof Krux !== "undefined" ? { ksg: Krux.segments, kuid: Krux.user } : {};

  try {
    // Use the targeting data of the article the ad belongs to
    // And use the first article for the out-of-page and leaderboard ads
    const page_targeting = el.closest("article").length
      ? el.closest("article").prev().data("page_targeting")
      : $(".pubstack-post-header").first().data("page_targeting");
    return { ...page_targeting, ...krux_data };
  } catch (e) {
    return krux_data;
  }
};

function freestarAdsRequest() {
  this.initTimer = 0;
  this.init = function () {
    $(window).on("ad:request", this, this.freestarInit);
    $(window).on("ad:scroll_update", this, this.freestarPush);
    this.freestarInit();
  };

  this.freestarInit = () => {
    if (typeof freestar !== "undefined") {
      clearTimeout(this.initTimer);
      if (typeof PQ.loadSignals !== "undefined") {
        // Load DV Authentic Direct signals first
        PQ.loadSignals(["abs"], () => {
          freestarInitAction(); // Proceed with initializing Freestar
        });
      }
    } else {
      this.initTimer = setTimeout(() => {
        this.freestarInit();
      }, 500);
    }
  };

  const freestarInitAction = () => {
    freestar.config.targeting = [{ essence_adhesion: page_targeting() }];
    freestar.initCallback = function () {
      $(positions)
        .not(".observed")
        .slice(0, 100)
        .each((index, el) => {
          $(el).addClass("observed");
          ads_observer.observe(el);
        });
    };
  };

  this.freestarPush = function () {
    $(positions)
      .not(".observed")
      .slice(0, 100)
      .each((index, el) => {
        $(el).addClass("observed");
        ads_observer.observe(el);
      });
  };
}

var freestartAdsRequestInstance = new freestarAdsRequest();
freestartAdsRequestInstance.init();
